<template>
  <div id="app">
    <!-- <el-container> -->
      <el-header class="head-lg" height="75px">
          <div class="fixed width100 shadow-head">
            <div class="flex space-between">
              <div class="nav items-center flex relative">
                <div class="tab font-14 cursor-pointer red bold">
                  <div @click="goto('AllProducts')" class="tab-explore">
                    <i
                      class="el-icon-s-operation bold font-22 tab-explore-p"
                    ></i>
                    <span>EXPLORE</span>
                  </div>
                  <div class="displayNone absolute">
                    <ul class="tab-ul flex items-center">
                      <li @click="goto('ART')">ART</li>
                      <li @click="goto('GAME')">GAME</li>
                      <li @click="goto('PFPS')">PFPS</li>
                      <li @click="goto('PHOTOGRAPHY')">PHOTOGRAPHY</li>
                    </ul>
                  </div>
                </div>
                <div @click="$router.push('/information')" class="tab font-14 cursor-pointer red bold">
                  <div class="tab-explore">
                    <i class="el-icon-document bold font-22 tab-explore-p"></i
                    ><span>KNOW MORE</span>
                  </div>
                </div>
                <div @click="$router.push('/deliveryInformation')" class="tab font-14 cursor-pointer red bold">
                  <div class="tab-explore">
                    <i
                      class="el-icon-shopping-cart-full bold font-22 tab-explore-p"
                    ></i
                    ><span>HOWTO</span>
                  </div>
                </div>
                <div @click="login" class="tab font-14 cursor-pointer red bold">
                  <div class="tab-explore">
                    <i class="el-icon-user bold font-22 tab-explore-p"></i
                    ><span>SIGN IN</span>
                  </div>
                </div>
              </div>
              <div @click="$router.push('/')" style="height:75px;margin-right:100px;">
                <img src="/img/logo.png" alt="" width="100%" height="100%" />
              </div>
              <div class="tab-right flex items-center">
                <div class="">
                  <!-- <i class="el-icon-star-off font-30 tab-explore-p tab-icon-pr"></i> -->
                  <i
                    class="el-icon-search font-30 tab-explore-p tab-icon-pr"
                  ></i>
                  <i @click="$router.push('/shopping')"
                    class="el-icon-shopping-bag-2 font-30 tab-explore-p tab-icon-pr relative"
                  >
                  <div v-if="cartListNum"class="num">{{cartListNum}}</div>
                </i>
                </div>
              </div>
            </div>
          </div>
      </el-header>
      <el-header class="head-md" height="60px">
        <div class="fixed width100 shadow-head">
          <div class="flex space-between items-center">
            <div class="flex items-center">
              <div @click="$router.push('/')" style="padding:18px;height:28px;">
                <img src="/img/logo.png" alt="" width="100%" height="100%" />
              </div>
              <div @click="login">
                <i class="el-icon-user bold font-12 tab-explore-p"></i><span>SIGN IN</span>
              </div>
            </div>
            <div>
              <i @click="dialogVisible =true" class="el-icon-s-operation bold font-22 tab-icon-pr"></i>
              <i class="el-icon-search font-22 tab-explore-p tab-icon-pr"></i>
              <i @click="$router.push('/shopping')" class="el-icon-shopping-bag-2 font-22 tab-explore-p tab-icon-pr relative">
                <div v-if="cartListNum" class="nummin">{{cartListNum}}</div>
              </i>
            </div>
            <el-dialog
              top='0px'
              :visible.sync="dialogVisible"
              custom-class="side-dialog"
              width="85%">
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose">
                <el-submenu index="1">
                  <template slot="title">
                    <i class="el-icon-menu"></i>
                    <span>Explore</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="1-1">ART</el-menu-item>
                    <el-menu-item index="1-2">GAME</el-menu-item>
                    <el-menu-item index="1-3">PFPS</el-menu-item>
                    <el-menu-item index="1-4">PHOTOGRAPHY</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <el-menu-item index="2">
                  <i class="el-icon-menu"></i>
                  <span slot="title">Know More</span>
                </el-menu-item>
                <el-menu-item index="3">
                  <i class="el-icon-menu"></i>
                  <span slot="title">HowTo</span>
                </el-menu-item>
                <el-menu-item index="4">
                  <i class="el-icon-menu"></i>
                  <span slot="title">Sign In</span>
                </el-menu-item>
                <el-menu-item index="5">
                  <i class="el-icon-menu"></i>
                  <span slot="title">Register</span>
                </el-menu-item>
              </el-menu>
            </el-dialog>
            </div>
        </div>
      </el-header>

      <router-view v-if="isRefresh" />

      <el-footer height='auto' :style="web?'':'padding:10px'">
        <div style="padding: 20px 20px 40px" class="bgcolor">
          <el-row :gutter="10" style="max-width:1280px;margin: 0 auto">
            <el-col :xs="24" :sm="6" class="divcon" :style="web?'':'padding-bottom:20px'">
              <div class="foottitle">ABOUT US</div>
              <div @click="$router.push('/about')">About Us</div>
              <div @click="$router.push('/deliveryInformation')">Delivery</div>
              <div @click="$router.push('/privacyPolicy')">Privacy Policy</div>
              <div @click="$router.push('/termsConditions')">Terms & Conditions</div>
            </el-col>
            <el-col :xs="24" :sm="6" class="divcon">
              <div class="foottitle">ABOUT US</div>
              <div @click="login">My Account</div>
              <div @click="login">Order History</div>
              <div @click="login">Newsletter</div>
            </el-col>
          </el-row>
        </div>
        <div style="padding:20px">
          <div style="max-width:1280px;margin: 0 auto">
            <div :style="web?'height: 50px;line-height:50px;font-size:16px':'text-align:center;font-size:14px'">Copyright © 2024, QIANYUAN, All Rights Reserved</div>
          </div>
        </div>
      </el-footer>
    <!-- </el-container> -->
    <Login ref="login" />
  </div>
</template>
<script>
import './style/app.css'
import { mapState } from "vuex"
import Login from './views/login.vue'
export default {
    name: 'app',
    data () {
        return {
          web: this.$utils.isDesktop(),
          isRefresh: true,
          dialogVisible: false,
          // num: JSON.parse(sessionStorage.getItem('cartList')) ?  JSON.parse(sessionStorage.getItem('cartList')).length : ''
        }
    },
    computed:{
			...mapState({
				cartListNum: state => state.user.cartListNum
			})
		},
    components: { Login },
    methods: {
      handleOpen () {},
      handleClose () {},
      login () {
        this.$refs.login.open()
      },
      refresh() {
        this.isRefresh = false;
        this.$nextTick(() => {
          this.isRefresh = true;
        });
      },
      goto (title) {
        this.$router.push({
          path:'/catalog',
          query:{ title: title }
        })
        this.refresh()
      }
    },
    mounted () {
    },
}
</script>
<style lang="less">
.shadow-head .el-dialog {
  right: 0;
  transition: all 5s;
  margin: 0 !important;
  height: 100%;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  box-shadow: 5px 20px 40px rgba(0, 0, 0, 0.3);
}
.shadow-head .el-dialog__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.shadow-head .el-dialog__headerbtn {
  font-size: 20px
}
.divcon {
  color: #727684;
  div {
    padding: 0 0 7px;
    font-size: 16px;
    cursor: pointer;
  }
}
.num {
  width: 14px;
  height: 14px;
  font-size: 12px;
  color: #ffffff;
  background: #d01e24;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 14px;
}
.nummin {
  width: 12px;
  height: 12px;
  font-size: 10px;
  color: #ffffff;
  background: #d01e24;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 12px;
}
</style>
